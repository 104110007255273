.QueueWidget {
    
    // font-family: Arial;
    font-size: 12px;
    padding: 5px;

    a {
        // color: #93c7ff;
    } 

    .footer {
        clear: both;
        text-align: center;
        padding: 10px;
    }
        
    .row {
        margin: 0;
    }

    > div {
        background-color: var(--widget-background);
        box-shadow: var(--widget-shadow);
        height: 100%;
        overflow: hidden;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 0 5px;
        vertical-align: middle;
    }

    // tr:first-child td {
    //     border-top: 0px;
    //     height: 38px;
    //     background: none;
    // }
    td:first-child {
        border-left: 0px;
    }

    td:last-child {
        border-right: 0px;
    }

    .header {
        line-height: 27px;
        .title {
            font-size: 14px;
            font-weight: 700;
        }
        .stats {
            font-size: 12px;
            float: right;
            .sla-percentage, .stats .sla-wait {
                color: red;
            }
            .success {
                color: #55c555;
            }
        }
        .close-queue{
            background: url("./leds.png") 0px 0px no-repeat;
            height: 16px;
            width: 16px;
            display: inline-table;
            background-position: -96px 0px;
            margin-left: 5px;
            margin-top: 5px;
            cursor: pointer;
        }
    }

    .info {
        opacity: 0.5;
        font-size: 14px;
        i {
            margin-left: 7px;
        }
    }

    .info-members td {
        background: rgba(33,40,50,.03);
        font-size: 11px;
        text-align: center;
        height: 26px;
        padding: 5px;
    }

    .center {
        text-align: center;
        vertical-align: middle;
    }

    tr.call:nth-child(odd) td {
        background: rgba(0, 0, 0, 0.01);
    }

    tr.call:nth-child(even) td {
        background: rgba(0, 0, 0, 0.05);
    }


    .call td, .customer td {
        padding: 5px;
        height: 29px;
    }

    .call .presence div {
        background: url("./leds.png") 0px 0px no-repeat;
        height: 16px;
        width: 16px;
        display: inline-table;
    }

    .call .presence.offline div {
        background-position: -80px 0px;
    }
    .call .presence.away div {
        background-position: -32px 0px;
    }
    .call .presence.dnd div {
        background-position: -64px 0px;
    }
    .call .presence.talking div {
        background-position: -128px 0px;
    }
    .call .presence.ringing div {
        background-position: -192px 0px;
    }
    .call .presence.pause div {
        background-position: -176px 0px;
    }



    .call .name div, .call .callee div {
        overflow: hidden;
        border-collapse: collapse;
        white-space: nowrap;
        width: 120px;
        text-overflow: ellipsis;
    }

    .call .name span {
        font-size: smaller;
    }

    .customer.timeout td {
        background: #ffbaba;
    }

    .customer td:first-child {
        background: none;
        border: 0;
    }
    .add {
        background: url("./leds.png") -160px 0px no-repeat;
        height: 16px;
        width: 16px;
        display: inline-table;
        cursor: pointer;
    }
    .remove-member.remove div {
        background: url("./leds.png") -96px 0px no-repeat;
        height: 16px;
        width: 16px;
        display: inline-table;
        cursor: pointer;
    }


    .status {
        padding: 20px;
    }
}