// Bootstrap overrides
body{
    color: var(--body-color);
}
.dropdown-menu{
    .dropdown-header{
        color: inherit;
        opacity: 0.7;
    }
}
.navbar{
    background-color: var(--nav-background) !important;
    .navbar-brand {
        color: var(--nav-brand);
    }
    .navbar-toggler {
        color: rgba(var(--nav-toggler), 0.5);
        border-color: rgba(var(--nav-toggler) , 0.1);
    }
    .navbar-toggler-icon {
        background-image: var(--nav-toggler-svg);
    }
    .nav-link{
        color: var(--nav-link-color) !important;
        &.active{
            color: var(--nav-link-color-active) !important;
        }
    }
}
.btn-theme{
    color: var(--btn-gpoup-color);
    background-color:var(--btn-gpoup-background);
    border-color: var(--btn-gpoup-border);
    &.active{
        color: var(--btn-gpoup-color-active);
        background-color:var(--btn-gpoup-background-active);
        border-color: var(--btn-gpoup-border-active);
    }
}
.dropdown-menu {
    color: var(--dropdown-menu-color);
    background-color: var(--dropdown-menu-background);
    .dropdown-item {
        color: var(--dropdown-menu-item-color);
        &:hover{
            color: var(--dropdown-menu-item-color-hover);
            background-color: var(--dropdown-menu-item-background-hover);
        }
    }
}

.modal-dialog, .modal-content {
    background: var(--modal-content-background);
    color: var(--modal-content-color);
}

.form-control {
    background: var(--form-control-background);
    color: var(--form-control-color);
    border: var(--form-control-border);
}
.card{
    background: var(--card-background);
    color: var(--card-color);
}
//

// Themes: default light
body{
    --body-background: #f3f3f4;
    --body-color: #212529;
    --shadow: 0 0.15rem 0.75rem 0 rgba(33, 40, 50, 0.2);

    --nav-brand: var(--dark);
    --nav-toggler: 0, 0, 0;
    --nav-toggler-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --nav-background: var(--light);
    --nav-shadow: var(--shadow);
    --nav-link-color: rgba(var(--nav-toggler), 0.5);
    --nav-link-color-active: rgb(var(--nav-toggler));
    
    --sidebar-brand-color: var(--dark);
    --sidebar-background: var(--light);
    --sidebar-shadow: var(--shadow);
    --list-group-item-action: #495057;
    --list-group-item-action-hover-background: #cfcfcf;

    --widget-background: #fff;
    --widget-shadow: var(--shadow);
    
    --btn-gpoup-color: #212529;
    --btn-gpoup-background: #f8f9fa;
    --btn-gpoup-border: #f8f9fa;
    --btn-gpoup-color-active: #212529;
    --btn-gpoup-background-active: #dae0e5;
    --btn-gpoup-border-active: #d3d9df;

    --form-control-background: var(--widget-background);
    --form-control-color: var(--body-color);
    --form-control-border: 1px solid var(--body-background);

    --card-background: var(--nav-background);
    --card-color: var(--body-color);

    --modal-content-background: var(--widget-background);
    --modal-content-color: var(--body-color);

    --dropdown-menu-color: var(--body-color);
    --dropdown-menu-background: var(--widget-background);
    --dropdown-menu-item-color: var(--body-color);
    --dropdown-menu-item-color-hover: var(--list-group-item-action);
    --dropdown-menu-item-background-hover: var(--list-group-item-action-hover-background);
}


body.DarkTheme {
    --body-background: var(--gray);
    --body-color: var(--light);
    --shadow: 0 0.15rem 0.75rem 0 rgba(255, 255, 255, 0.3);

    --nav-brand: var(--light);
    --nav-toggler: 255, 255, 255;
    --nav-toggler-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --nav-background: var(--dark);
    
    --sidebar-brand-color: var(--light);
    --sidebar-background: var(--dark);
    --list-group-item-action: var(--light);
    --list-group-item-action-hover-background: var(--gray);

    --widget-background: var(--dark);

    --btn-gpoup-color: #fff;
    --btn-gpoup-background: #43494f;
    --btn-gpoup-border: #4f5255;
    --btn-gpoup-color-active: #fff;
    --btn-gpoup-background-active: #1d2124;
    --btn-gpoup-border-active: #171a1d;
}

body.PinkTheme {
    --body-background: pink;
    --body-color: var(--light);
    --shadow: 0 0.15rem 0.75rem 0 rgba(33, 40, 50, 0.2);

    --nav-brand: red;
    --nav-toggler: red;
    --nav-toggler-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --nav-background: #51272f;
    
    --sidebar-brand-color: var(--body-color);
    --sidebar-background: var(--nav-background);
    --list-group-item-action: var(--light);
    --list-group-item-action-hover-background: var(--gray);

    --widget-background: var(--nav-background);

    --btn-gpoup-color: #fff;
    --btn-gpoup-background: #43494f;
    --btn-gpoup-border: #4f5255;
    --btn-gpoup-color-active: #fff;
    --btn-gpoup-background-active: #1d2124;
    --btn-gpoup-border-active: #171a1d;
}