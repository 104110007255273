.UserButton {
  .user-botton-icon {
    // position: absolute;
    // left: 6px;
    // height: 1.6em;
    position: absolute;
    left: -20px;
    height: 40px;
    top: -5px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .dropdown-toggle {
    width: 150px;
    height: 32px;
    text-align: right;
    padding-top: 0px;
    padding-bottom: 12px;

    &::after {
      vertical-align: -0.3em;
    }

    small {
      font-size: xx-small;
      position: absolute;
      right: 19px;
      top: 16px;
    }
  }
}
