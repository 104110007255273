.Queues {
  
    padding-top: 50px;
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .row {
      margin-right: -5px;
      margin-left: -5px;
    }

    .list-group-item{
      background-color: transparent;
      color: var(--list-group-item-action);

      &:hover{
        background-color: var(--list-group-item-action-hover-background);
      }
      &:active{
        background-color: #888888;
        color: #000000;
      }
    }

    &.toggled {
      padding-left: 250px;

      #sidebar-wrapper {
        width: 250px;
      }

      #page-content-wrapper {
        position: absolute;
        margin-right: 0px;
      }

    }

    #sidebar-wrapper {
      z-index: 1000;
      position: fixed;
      left: 250px;
      width: 0;
      height: 100%;
      margin-left: -250px;
      overflow-y: auto;
      background: var(--sidebar-background);
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      box-shadow: var(--sidebar-shadow);
    }
    
    #page-content-wrapper {
      width: 100%;
      position: absolute;
      padding: 5px;
    }
      

  /* Sidebar Styles */

  .sidebar-nav {
    position: absolute;
    // top: 55px;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
  }
  
  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #505050;
    cursor: default;
  }
  
  .sidebar-nav li a.enabled {
    color: #999999;
    cursor: pointer;
  }
  
  .sidebar-nav li a.enabled:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .sidebar-nav li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
  }
  
  .sidebar-brand {
    font-size: 18px;
    padding: 0.75rem 1.25rem;
    color: var(--sidebar-brand-color);
  }
  .modal{
    z-index: 101000;
    color: black;
  }
}
