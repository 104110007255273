html, body {
  background: var(--body-background) !important;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Arial;
  font-size: 14px;
  // color: #fff;
  overflow-x: hidden;
}

.myclass{
  // add some cool style; 
  background-color: transparent;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "~bootstrap/scss/bootstrap";